<template>
  <main>
    <div class="contenu3">

      <div style="position:relative;">
        <!-- mobile -->
        <div class="logo mobile"><router-link to="/"><img  src="../assets/images/logo.png"></router-link></div>
        <img class="top-mobile mobile" src="../assets/images/top-vague3-mobile.jpg">
        <!-- commun -->
        <div class="top-form"><img class="imgjouer" src="../assets/images/jouez.png"></div>
      </div>


      <div class="bh-bleu"><img src="../assets/images/bonhomme-bleu.png"></div>
       <div class="bh-rouge"><img src="../assets/images/bonhomme-rouge.png"></div>   
      <div class="sepa-form"></div>

      <div class="form">
        <Form :class="'form_step'+step" 
              id="monform" 
              :form_id="form_id" 
              :server="server" 
              :hideError="true"
              @on_error="viewError"
              v-on:results="getResult"
              v-on:step-up="checkStep" 
              v-on:step-down="checkStep" 
              v-on:form_submit_loading=loading />
      </div>
        <div class="captcha">
          This site is protected by reCAPTCHA and the Google<br />
          <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
          <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
        </div>
    <img class="mobile" src="../assets/images/bottom-contact.jpg" width="100%">

    </div>
  </main>
</template>
<script>
var consts = require('../config');
import axios from "axios";

export default {
  name: "form",
  data: function () {
    return {
      step: 0,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    viewError(e) {
     alert(e)
       this.$swal.close()
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          title: 'Envoi en cours',
          html: 'Merci de bien vouloir patienter ...',
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading()
          }
        })
      }
    },
    checkStep() {
       this.step = this.step++
    },
    getResult(result) {
      if (result._id) {

        if(result.lot == 'parc') { window.lot = 'lot1' }
        else if(result.lot == 'cultura') { window.lot = 'lot4'}
        else if(result.lot == 'cuisine') { window.lot = 'lot2'}
        else if(result.lot == 'coiffure') { window.lot = 'lot3'}
        else if(result.lot == 'shooting') { window.lot = 'lot5'}
        else {
          window.lot = 'perdu'
        }

        this.$swal.close()
        this.$router.push("/bravo");
      } else {
        alert('Erreur interne')
      }
    },
  },
  mounted() {},
  created: function () {}
} 
</script>
